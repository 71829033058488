import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import QuestNavItem from './quest-nav-item';

const boxShadowBottom = `0px -1px 4px 0px rgba(50,50,50,0.2), 0px 0px 5px 0px rgba(50,50,50,0.14), 0px 0px 10px 0px rgba(50,50,50,0.12)`;

const styles = (theme) => ({
    questNavPanel: {
        borderTop: `1px solid #000`,
        borderBottom: `1px solid #000`,
        backgroundColor: theme.palette.secondary.main,
        opacity: 0.97,
        boxShadow: boxShadowBottom,
        position: `fixed`,
        top: `auto`,
        bottom: 0,
        minHeight: `40px`,
        maxHeight: `84px`,
        width: `100%`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        paddingBottom: `4px`,
        zIndex: 20,
        [theme.breakpoints.up(`sm`)]: {
            height: `70px`,
            display: `flex`,
            alignItems: `center`,
            padding: `0 20px`,
            flexDirection: `row`,
            paddingBottom: 0
        }
    },
    questNavContainer: {
        width: `100%`,
        display: `flex`,
        alignItems: `center`,
        [theme.breakpoints.up(`sm`)]: {
            width: `70%`
        },
        [theme.breakpoints.up(`md`)]: {
            width: `75%`
        }
    },
    goBackLink: {
        textAlign: `center`,
        height: `30px`,
        padding: `4px 0`,
        textTransform: `uppercase`,
        fontSize: `15px`,
        position: `relative`,
        top: 1,
        '& a:hover': {
            textDecoration: `none`
        },
        [theme.breakpoints.up(`sm`)]: {
            fontSize: `13px`,
            height: `auto`,
            padding: 0,
            width: `auto`,
            margin: `0 auto`
        },
        [theme.breakpoints.up(`md`)]: {
            fontSize: `15px`
        },
        [theme.breakpoints.up(`lg`)]: {
            fontSize: `15px`
        }
    }
});

class QuestNavPanel extends React.Component {
    gotoNextQuest = () => {
        window.location = this.props.nav.next.url;
    };

    gotoPreviousQuest = () => {
        window.location = this.props.nav.previous.url;
    };

    render() {
        const { classes, previous, next, realm } = this.props;

        return (
            <nav className={classes.questNavPanel}>
                {(previous || next) && (
                    <div className={classes.questNavContainer}>
                        {previous && (
                            <QuestNavItem
                                url={previous.url}
                                title={previous.title}
                                id={previous.id}
                                colour={`#E2583E`}
                                icon={`chevron-left`}
                                prefixText={`Prev Quest: `}
                                hotkeys={`alt+p,⌥+p`}
                                tooltipTitle={`Previous quest (alt+p | ⌥+p)`}
                            />
                        )}
                        {next && (
                            <QuestNavItem
                                url={next.url}
                                title={next.title}
                                id={next.id}
                                colour={`#059A92`}
                                icon={`chevron-right`}
                                iconRight={true}
                                prefixText={`Next Quest: `}
                                tooltipTitle={`Next quest (alt+n | ⌥+n)`}
                                hotkeys={`alt+n,⌥+n`}
                            />
                        )}
                    </div>
                )}
                {realm && (
                    <div
                        className={classNames(
                            classes.goBackLink,
                            `text-shadow`,
                            `montserrat`
                        )}
                    >
                        <a href={realm.url}>
                            <span>{realm.title}</span>
                        </a>
                    </div>
                )}
            </nav>
        );
    }
}

QuestNavPanel.propTypes = {
    previous: PropTypes.object.isRequired,
    next: PropTypes.object.isRequired,
    realm: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(QuestNavPanel);
