import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Modal from '@material-ui/core/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetIcon from '../../utils/getIcon';
import InsertInlineCodeElements from '../../utils/insertInlineCodeElements';
import Message from '../message';
import Results from './results';
import Score from './score';
import classNames from 'classnames';

const styles = (theme) => ({
    stepper: {
        padding: `0`,
        '& > div:last-child > div': {
            borderLeft: `none`
        },
        margin: `32px 0`
    },
    step: {
        position: `relative`,
        top: -10,
        marginBottom: `8px`,
        [theme.breakpoints.up('sm')]: {
            top: -2
        }
    },
    stepLabel: {
        '& > span': {
            paddingRight: `4px`
        }
    },
    stepIcon: {
        fontWeight: 700,
        position: `relative`,
        top: -2,
        left: 4,
        marginRight: 0,
        fontSize: `36px`,
        fontFamily: `Londrina Solid`,
        color: theme.palette.primary.main,
        width: `36px`,
        height: `36px`
    },
    stepLabelContent: {
        margin: 0,
        paddingTop: 15,
        fontWeight: 600,
        fontSize: `21px`,
        borderBottom: `none`,
        [theme.breakpoints.up('md')]: {
            fontSize: `24px`
        }
    },
    stepContent: {
        padding: `20px 34px 0`,
        [theme.breakpoints.up('sm')]: {
            padding: `30px 60px 0`
        },
        borderLeft: `1px solid ${theme.palette.primary.main}`
    },
    comment: {
        display: `flex`,
        alignItems: `top`,
        marginBottom: `40px`
    },
    commentIcon: {
        color: theme.palette.secondary.main,
        marginRight: `7px`,
        fontSize: `18px`,
        position: `relative`,
        top: 5
    },
    commentText: {
        fontSize: `18px`
    },
    instruction: {
        fontSize: `17px`,
        color: `#FFF`,
        backgroundColor: theme.palette.secondary.main,
        padding: `8px`,
        borderRadius: `4px`,
        display: `inline`,
        marginLeft: `-2px`,
        border: `1px solid #000`
    },
    number: {
        fontFamily: `Londrina Solid`
    },
    orange: {
        color: theme.palette.primary.main
    },
    answers: {
        margin: `40px 0 20px`
    },
    answer: {
        marginBottom: `12px`,
        '& span': {
            fontSize: `1.3em`,
            fontFamily: `Raleway`
        }
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontWeight: 600,
        border: `1px solid #000`
    },
    actionsContainer: {
        marginBottom: theme.spacing(2)
    },
    scoreContainer: {},
    scoreButtons: {
        textAlign: `center`,
        marginBottom: `50px`
    },
    modal: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: `5%`,
        outline: 'none',
        top: `50%`,
        left: `50%`,
        transform: `translate(-55%, -50%)`,
        borderRadius: `4px`,
        margin: `0 5%`,
        width: `90%`,
        maxHeight: `90%`,
        overflowY: `hidden`,
        [theme.breakpoints.up('sm')]: {
            margin: `0`,
            padding: theme.spacing(4),
            width: `60%`,
            transform: `translate(-50%, -50%)`
        },
        [theme.breakpoints.up('md')]: {
            width: `40%`
        },
        border: `1px solid #000`
    },
    modalTitle: {
        fontWeight: 600,
        fontSize: `20px`,
        [theme.breakpoints.up('sm')]: {
            fontSize: `26px`
        },
        width: `100%`,
        backgroundColor: `#FFF`,
        zIndex: `10`
    },
    submitModalTitle: {
        fontSize: `24px`,
        [theme.breakpoints.up('sm')]: {
            fontSize: `30px`
        }
    },
    resultsModal: {
        height: `90%`,
        [theme.breakpoints.up('sm')]: {
            margin: `0`,
            width: `80%`,
            transform: `translate(-50%, -50%)`
        },
        [theme.breakpoints.up('md')]: {
            width: `70%`
        },
        border: `1px solid #000`
    },
    resultsModalTitle: {
        fontSize: `32px`,
        margin: 0
    },
    resultsModalClose: {
        float: `right`,
        margin: `7px 0`,
        color: theme.palette.secondary.main,
        fontSize: `30px`,
        cursor: `pointer`
    },
    results: {
        overflowY: `auto`,
        width: `100%`,
        height: `90%`
    }
});

let questions = [];

class Quiz extends React.Component {
    constructor(props) {
        super(props);

        let count = 0;
        let questionKeys = Object.keys(this.props.questions);
        questionKeys.sort(() => Math.random() - 0.5);

        questionKeys.forEach((key) => {
            questions[count++] = this.props.questions[key];
        });

        Object.keys(questions).forEach((key) => {
            questions[key].answers.sort(() => Math.random() - 0.5);
        });

        this.state = {
            activeStep: 0,
            responses: this.getQuestionIds(),
            modalErrorOpen: false,
            modalSubmitOpen: false,
            modalResultsOpen: false
        };
    }

    getQuestionIds = () => {
        let questionIds = {};
        Object.keys(questions).forEach((key) => {
            questionIds[questions[key].id] = {};
        });
        return questionIds;
    };

    handleNext = () => {
        if (this.hasSelectedAllOptions(questions[this.state.activeStep].id)) {
            this.setState((state) => ({
                activeStep: state.activeStep + 1
            }));
            this.handleSubmitModalClose();
            return;
        }
        this.handleErrorModalOpen();
    };

    handleBack = () => {
        this.setState((state) => ({
            activeStep: state.activeStep - 1
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
            responses: this.getQuestionIds()
        });
    };

    handleRadioChange = (event) => {
        let values = event.target.value.split('.');
        let responsesStateClone = this.state.responses;
        responsesStateClone[values[0]] = values[1];
        this.setState({ responses: responsesStateClone });
    };

    handleCheckedChange = (event) => {
        let values = event.target.value.split('.');
        let responsesStateClone = this.state.responses;
        responsesStateClone[values[0]][values[1]] = event.target.checked;
        this.setState({ responses: responsesStateClone });
    };

    hasSelectedAllOptions = (questionId) => {
        return (
            this.getTotalSelectedOptions(questionId) ===
            questions[this.state.activeStep].totalCorrectAnswers
        );
    };

    getTotalSelectedOptions = (questionId) => {
        const questionResponses = this.state.responses[questionId];

        if (typeof questionResponses === `object`) {
            let totalSelectedOptions = 0;

            Object.keys(questionResponses).forEach(function (key) {
                if (questionResponses[key]) {
                    totalSelectedOptions++;
                }
            });
            return totalSelectedOptions;
        }

        if (questionResponses !== null) {
            return 1;
        }
    };

    getLabel = (label, index) => {
        if (this.state.activeStep >= index) {
            return label;
        }
        return '...';
    };

    getAnswers = (question) => {
        if (question.totalCorrectAnswers === 1) {
            return (
                <RadioGroup onChange={this.handleRadioChange}>
                    {Object.keys(question.answers)
                        .filter((key) => question.answers[key].label)
                        .map((key) => (
                            <FormControlLabel
                                control={
                                    <Radio
                                        icon={
                                            <FontAwesomeIcon
                                                icon={GetIcon(`circle`)}
                                            />
                                        }
                                        checkedIcon={
                                            <FontAwesomeIcon
                                                icon={GetIcon(`dot-circle`)}
                                            />
                                        }
                                        value={`${question.id}.${key}`}
                                        checked={
                                            this.state.responses[
                                                question.id
                                            ] === key
                                        }
                                    />
                                }
                                key={`${question.id}.${key}`}
                                label={InsertInlineCodeElements(
                                    question.answers[key].label
                                )}
                                className={classNames(
                                    this.props.classes.answer,
                                    `raleway`
                                )}
                            />
                        ))}
                </RadioGroup>
            );
        }

        if (question.totalCorrectAnswers > 1) {
            return (
                <FormGroup onChange={this.handleCheckedChange}>
                    {Object.keys(question.answers)
                        .filter((key) => question.answers[key].label)
                        .map((key) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        icon={
                                            <FontAwesomeIcon
                                                icon={GetIcon(`square`)}
                                            />
                                        }
                                        checkedIcon={
                                            <FontAwesomeIcon
                                                icon={GetIcon(`check-square`)}
                                            />
                                        }
                                        checked={
                                            this.state.responses[question.id][
                                                key
                                            ]
                                        }
                                    />
                                }
                                key={`${question.id}.${key}`}
                                value={`${question.id}.${key}`}
                                label={InsertInlineCodeElements(
                                    question.answers[key].label
                                )}
                                className={this.props.classes.answer}
                            />
                        ))}
                </FormGroup>
            );
        }
    };

    handleErrorModalOpen = () => {
        this.setState({ modalErrorOpen: true });
    };

    handleErrorModalClose = () => {
        this.setState({ modalErrorOpen: false });
    };

    getRequiredAnswersTotal = () => {
        if (questions[this.state.activeStep]) {
            return questions[this.state.activeStep].totalCorrectAnswers;
        }
    };

    handleSubmitModalOpen = () => {
        if (this.hasSelectedAllOptions(questions[this.state.activeStep].id)) {
            this.setState({ modalSubmitOpen: true });
            return;
        }
        this.setState({ modalErrorOpen: true });
    };

    handleSubmitModalClose = () => {
        this.setState({ modalSubmitOpen: false });
    };

    handleResultsModalOpen = () => {
        this.setState({ modalResultsOpen: true });
    };

    handleResultsModalClose = () => {
        this.setState({ modalResultsOpen: false });
    };

    render() {
        const { classes } = this.props;
        const { activeStep, responses } = this.state;

        return (
            <div>
                <div hidden={activeStep === Object.keys(questions).length}>
                    <Message
                        type={`info`}
                        title={`Trial Overview`}
                        closeButton={true}
                    >
                        <ul>
                            <li>
                                <span>
                                    {`Trials test your knowledge of the current
                                    topic via a multiple choice quiz.`}
                                </span>
                            </li>
                            <li>
                                <span>
                                    {`You'll receive a score upon completion and
                                    feedback on the answers you got right or
                                    wrong along with further guidance.`}
                                </span>
                            </li>
                            <li>
                                <span>
                                    {`Ensure to read up on the concepts within the
                                    'Story' and complete the 'Walkthru' (where
                                    present) before attempting!`}
                                </span>
                            </li>
                        </ul>
                    </Message>
                </div>
                <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    className={classes.stepper}
                    connector={null}
                >
                    {Object.keys(questions).map((key) => (
                        <Step key={questions[key].id} className={classes.step}>
                            <StepLabel
                                StepIconComponent={() => {
                                    return (
                                        <span
                                            className={classNames(
                                                classes.stepIcon,
                                                `text-shadow`
                                            )}
                                        >
                                            {parseInt(key) + 1}
                                        </span>
                                    );
                                }}
                                className={classes.stepLabel}
                            >
                                <h2 className={classes.stepLabelContent}>
                                    {this.getLabel(
                                        questions[key].question,
                                        key
                                    )}
                                </h2>
                            </StepLabel>
                            <StepContent className={classes.stepContent}>
                                {questions[key].comment && (
                                    <div className={classes.comment}>
                                        <FontAwesomeIcon
                                            icon={GetIcon(`info-circle`)}
                                            className={classes.commentIcon}
                                        />
                                        <div className={classes.commentText}>
                                            {InsertInlineCodeElements(
                                                questions[key].comment
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className={classes.instruction}>
                                    Select{` `}
                                    <span className={classes.number}>
                                        {questions[key].totalCorrectAnswers}
                                    </span>
                                    {` `}
                                    {questions[key].totalCorrectAnswers > 1
                                        ? `answers.`
                                        : `answer.`}
                                </div>

                                <div className={classes.answers}>
                                    {this.getAnswers(questions[key])}
                                </div>

                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            disabled={activeStep === 0}
                                            onClick={this.handleBack}
                                            className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={
                                                activeStep ===
                                                Object.keys(questions).length -
                                                    1
                                                    ? this.handleSubmitModalOpen
                                                    : this.handleNext
                                            }
                                            className={classes.button}
                                        >
                                            {activeStep ===
                                            Object.keys(questions).length - 1
                                                ? 'Finish'
                                                : 'Next'}
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === Object.keys(questions).length && (
                    <Paper
                        square
                        elevation={0}
                        className={classes.scoreContainer}
                    >
                        <Score questions={questions} responses={responses} />

                        <div className={classes.scoreButtons}>
                            <Button
                                variant="contained"
                                onClick={this.handleReset}
                                className={classes.button}
                            >
                                Take Again
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleResultsModalOpen}
                                className={classes.button}
                            >
                                View results
                            </Button>
                        </div>
                    </Paper>
                )}
                <Modal
                    aria-labelledby="Question Answer Error"
                    aria-describedby="Error when not enough answers have been selected"
                    open={this.state.modalErrorOpen}
                    onClose={this.handleErrorModalClose}
                >
                    <div className={classes.modal}>
                        <h6 className={classes.modalTitle}>
                            Please select{` `}
                            <span
                                className={classNames(
                                    classes.number,
                                    classes.orange
                                )}
                            >
                                {this.getRequiredAnswersTotal()}
                            </span>
                            {` `}
                            {this.getRequiredAnswersTotal() > 1
                                ? `answers`
                                : `answer`}
                        </h6>
                        <Button
                            variant="contained"
                            onClick={this.handleErrorModalClose}
                            className={classes.button}
                        >
                            Close
                        </Button>
                    </div>
                </Modal>
                <Modal
                    aria-labelledby="Confirm trial submission"
                    aria-describedby="Submit your answers"
                    open={this.state.modalSubmitOpen}
                    onClose={this.handleSubmitModalClose}
                >
                    <div className={classes.modal}>
                        <h6
                            className={classNames(
                                classes.modalTitle,
                                classes.submitModalTitle
                            )}
                        >
                            Are you sure?
                        </h6>
                        <Button
                            variant="contained"
                            onClick={this.handleSubmitModalClose}
                            className={classes.button}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleNext}
                            className={classes.button}
                        >
                            Submit Answers
                        </Button>
                    </div>
                </Modal>
                <Modal
                    aria-labelledby="Trial results"
                    aria-describedby="View your answers"
                    open={this.state.modalResultsOpen}
                    onClose={this.handleResultsModalClose}
                >
                    <div
                        className={classNames(
                            classes.modal,
                            classes.resultsModal
                        )}
                    >
                        <h2
                            className={classNames(
                                classes.modalTitle,
                                classes.resultsModalTitle
                            )}
                        >
                            Results
                            <FontAwesomeIcon
                                icon={GetIcon(`times-circle`)}
                                onClick={this.handleResultsModalClose}
                                className={classes.resultsModalClose}
                            />
                        </h2>
                        <div className={classes.results}>
                            <Results
                                questions={questions}
                                responses={responses}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

Quiz.propTypes = {
    classes: PropTypes.object,
    questions: PropTypes.object
};

export default withStyles(styles, { withTheme: true })(Quiz);
