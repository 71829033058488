import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import Layout from '../components/layout';
import QuestNavPanel from '../components/quest-nav-panel';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import Link from '@material-ui/core/Link';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as LinkItem, RichText } from 'prismic-reactjs';
import PrismicSliceContent from '../utils/prismicSliceContent';
import withRoot from '../withRoot';
import GetIcon from '../utils/getIcon';
import Message from '../components/message';
import MetaPanel from '../components/meta-panel';
import Quiz from '../components/quiz/quiz';
import QuestLink from '../components/quest-link';
import QuestMeta from '../components/quest-meta';
import LinkResolver from '../utils/linkResolver';
import Slugify from '../utils/slugify';
import Hotkeys from 'react-hot-keys';
import Tocbot from 'tocbot';
import Sticky from 'react-stickynode';
import scrollToElement from 'scroll-to-element';
import HtmlSerializer from '../utils/htmlSerializer';

const boxShadow =
    '0px 2px 4px -1px rgba(50,50,50,0.2), 0px 4px 5px 0px rgba(50,50,50,0.14), 0px 1px 10px 0px rgba(50,50,50,0.12)';

const styles = (theme) => ({
    meta: {
        margin: `-5px 0 0`,
        padding: `0 0 20px 20px`,
        [theme.breakpoints.up(`sm`)]: {
            margin: `-10px 0 0`,
            padding: `0 0 20px 30px`
        }
    },
    tabsSticky: {
        '& .sticky-inner-wrapper': {
            opacity: 0.97,
            backgroundColor: `#F3F3F3`,
            color: theme.palette.secondary.main
        },
        '&.active .sticky-inner-wrapper': {
            backgroundColor: theme.palette.secondary.light,
            color: `#FFF`
        },
        borderTop: `1px solid #000`
    },
    tabs: {
        zIndex: '999',
        width: '100%',
        boxShadow: boxShadow,
        borderBottom: `1px solid #000`
    },
    tab: {
        fontSize: `14.5px`,
        width: `25%`,
        minWidth: `100px`,
        paddingTop: `14px`,
        opacity: 1,
        [theme.breakpoints.up('md')]: {
            minWidth: `25%`
        }
    },
    disabled: {
        opacity: 0.4
    },
    tabIcon: {
        fontSize: '2em',
        minWidth: '1em',
        maxWidth: '1em',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.8em'
        }
    },
    selected: {
        '& svg': {
            color: theme.palette.primary.main,
            stroke: `#000`,
            strokeWidth: `15px`
        }
    },
    tabScroll: {
        backgroundColor: 'rgb(245, 245, 245)'
    },
    indicator: {
        backgroundColor: theme.palette.primary.main,
        height: 2
    },
    tabItem: {
        minWidth: `100px`,
        padding: '30px 20px',
        [theme.breakpoints.up('sm')]: {
            padding: '30px'
        },
        color: '#000',
        '& > *:first-child': {
            marginTop: 0
        },
        '& > div:first-child > *:first-child': {
            marginTop: 0
        },
        '& > div:first-child > div:first-child > *:first-child': {
            marginTop: 0
        },
        '& > div:first-child > div:first-child > div:first-child > *:first-child': {
            marginTop: 0
        }
    },
    story: {
        [theme.breakpoints.up('md')]: {
            display: `flex`
        }
    },
    storyContent: {
        [theme.breakpoints.up('md')]: {
            paddingRight: `15px`,
            width: `75%`
        },
        [theme.breakpoints.up('lg')]: {
            width: `80%`
        }
    },
    storyTocContainer: {
        display: `none`,
        [theme.breakpoints.up('md')]: {
            display: `block`,
            width: `25%`
        },
        [theme.breakpoints.up('lg')]: {
            width: `20%`
        }
    },
    storyToc: {
        display: `none`,
        [theme.breakpoints.up('md')]: {
            display: `block`
        },
        width: `100%`,
        color: theme.palette.primary.main,
        '& ul': {
            marginTop: 0,
            paddingTop: 0,
            fontFamily: `Montserrat`
        },
        '& li': {
            padding: `5px 5px`,
            borderRadius: `4px`
        },
        '& .is-active-li': {
            backgroundColor: theme.palette.primary.main,
            border: `1px solid #000`
        },
        '& a': {
            fontSize: `16px`,
            textTransform: `uppercase`,
            color: `#000`
        },
        '& a:hover': {
            textDecoration: `none`
        },
        '& .is-active-li a': {
            color: `#FFF`,
            textShadow: `-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000`,
            fontWeight: 700
        },
        '&.sticky': {
            width: `inherit`
        }
    },
    nextTab: {
        display: `flex`,
        width: `100%`,
        background: theme.palette.primary.main,
        borderRadius: `4px`,
        padding: 15,
        fontSize: `1.2em`,
        alignItems: `center`,
        cursor: `pointer`,
        border: `1px solid #000`
    },
    nextTabIcon: {
        fontSize: `1.4em`,
        maxHeight: `1.4em`,
        marginRight: 15,
        color: theme.palette.secondary.main
    },
    nextTabText: {
        color: `#FFF`,
        fontWeight: 500
    },
    nextTabTextSpan: {
        color: theme.palette.secondary.main,
        fontWeight: 600
    }
});

class QuestTabs extends React.Component {
    state = {
        index: 0
    };

    async componentDidMount() {
        const storyTab = document.getElementById(`tab-story`);
        storyTab.querySelectorAll(`h2`).forEach((heading) => {
            heading.setAttribute(`id`, Slugify(heading.innerText));
        });

        Tocbot.init({
            headingSelector: `h2`,
            positionFixedSelector: `.js-toc`,
            positionFixedClass: `sticky`,
            orderedList: false,
            fixedSidebarOffset: 200
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        Tocbot.refresh();
    }

    handleChange = (event, value) => {
        this.setState({ index: value });
        this.scrollToTop();
    };

    changeTabOnKeyPress = (keyName, event, handle) => {
        switch (keyName) {
            case `alt+1`:
            case `⌥+1`:
                this.setActiveTab(0);
                break;
            case `alt+2`:
            case `⌥+2`:
                this.setActiveTab(1);
                break;
            case `alt+3`:
            case `⌥+3`:
                this.setActiveTab(2);
                break;
            case `alt+4`:
            case `⌥+4`:
                this.setActiveTab(3);
                break;
            default: // do nothing
        }
    };

    setActiveTab = (index) => {
        this.setState({ index: index });
        this.scrollToTop();
    };

    scrollToTop = () => {
        scrollToElement(`#questTabsAnchor`, {
            offset: -60,
            duration: 1
        });
    };

    gotoWalkthru = () => {
        this.setActiveTab(1);
    };

    gotoTrial = () => {
        this.setActiveTab(2);
    };

    gotoJournal = () => {
        this.setActiveTab(3);
    };

    getDescriptionText = (quest) => {
        if (quest.description) {
            return RichText.asText(quest.description);
        }
        return RichText.asText(quest.name);
    };

    render() {
        const { index } = this.state;
        const { classes, data } = this.props;
        const quest = data.prismic.quest;
        const realm = data.prismic.realm;
        const trial = this.props.pageContext.trial;
        const id = this.props.pageContext.id;
        const realmQuests = this.props.pageContext.realmQuests;
        const name = RichText.asText(quest.name),
            realmName = RichText.asText(realm.name);

        let storyOutput = (
                <Message type={`warning`} title={`Coming soon!`}>
                    <p key={1}>
                        This quest is still a work in progress, check back soon
                        (or sign up to our newsletter below) for more updates!
                    </p>
                    <p key={2}>
                        Go back to:{` `}
                        <Link href={`/${realm._meta.uid}`} title={realmName}>
                            {realmName}
                        </Link>
                        , view{` `}
                        <Link href={`/realms`} title={`Realms`}>
                            all realms
                        </Link>
                        {` `}
                        or go{` `}
                        <Link href={`/`} title={`Home`}>
                            back home
                        </Link>
                        ?
                    </p>
                </Message>
            ),
            walkthroughOutput = (
                <Message type={`warning`} title={`Walkthru content not found!`}>
                    <p>No walkthru content found.</p>
                </Message>
            ),
            trialOutput = (
                <Message type={`warning`} title={`Trial not found!`}>
                    <p>No trial found for this quest.</p>
                </Message>
            ),
            journalOutput = (
                <Message type={`warning`} title={`Journal content not found!`}>
                    <p>No journal entries found for this quest.</p>
                </Message>
            );

        let storyOutputEnabled = true,
            walkthroughOutputEnabled = false,
            trialOutputEnabled = false,
            journalOutputEnabled = false;

        if (quest.story !== null && Object.entries(quest.story).length > 0) {
            storyOutput = PrismicSliceContent(quest.story);
        }

        if (quest.quest !== null && Object.entries(quest.quest).length > 0) {
            walkthroughOutput = PrismicSliceContent(quest.quest, true);
            walkthroughOutputEnabled = true;
        }

        if (trial !== undefined) {
            trialOutput = <Quiz questions={trial} />;
            trialOutputEnabled = true;
        }

        if (
            quest.journal !== null &&
            Object.entries(quest.journal).length > 0
        ) {
            journalOutput = PrismicSliceContent(quest.journal);
            journalOutputEnabled = true;
        }

        return (
            <Layout>
                <SEO
                    title={`${name} - ${realmName}`}
                    description={this.getDescriptionText(quest)}
                />
                <MetaPanel
                    title={name}
                    pageType={`Quest`}
                    icon={quest.icon}
                    parentTitle={RichText.asText(realm.name)}
                    parentLink={`/${realm._meta.uid}`}
                    parentPageType={`Realm`}
                >
                    <div className={classes.meta}>
                        <QuestMeta
                            date={quest._meta.lastPublicationDate}
                            magentoVersions={quest.magento_versions}
                            difficulty={quest.difficulty}
                            paddingRight={`10px`}
                        />
                    </div>
                </MetaPanel>
                <div id={`questTabsAnchor`} />
                <Sticky top={65} innerZ={999} className={classes.tabsSticky}>
                    <Tabs
                        value={index}
                        variant={`scrollable`}
                        scrollButtons={`off`}
                        classes={{
                            indicator: classes.indicator,
                            scrollButtons: classes.tabScroll
                        }}
                        onChange={this.handleChange}
                        className={classes.tabs}
                    >
                        <Tooltip title={`alt+1 | ⌥+1`}>
                            <Tab
                                icon={
                                    <FontAwesomeIcon
                                        icon={GetIcon(`book-reader`)}
                                        className={classes.tabIcon}
                                    />
                                }
                                label="Story"
                                className={classNames(classes.tab, {
                                    [classes.disabled]: !storyOutputEnabled
                                })}
                                classes={{
                                    selected: classes.selected
                                }}
                                key="title-story"
                                disabled={!storyOutputEnabled}
                            />
                        </Tooltip>
                        <Tooltip title={`alt+2 | ⌥+2`}>
                            <Tab
                                icon={
                                    <FontAwesomeIcon
                                        icon={GetIcon(`dungeon`)}
                                        className={classes.tabIcon}
                                    />
                                }
                                label={`Walkthru`}
                                className={classNames(classes.tab, {
                                    [classes.disabled]: !walkthroughOutputEnabled
                                })}
                                classes={{
                                    selected: classes.selected
                                }}
                                key="title-quest"
                                disabled={!walkthroughOutputEnabled}
                            />
                        </Tooltip>
                        <Tooltip title={`alt+3 | ⌥+3`}>
                            <Tab
                                icon={
                                    <FontAwesomeIcon
                                        icon={GetIcon(`swords`)}
                                        className={classes.tabIcon}
                                    />
                                }
                                label="Trial"
                                className={classNames(classes.tab, {
                                    [classes.disabled]: !trialOutputEnabled
                                })}
                                classes={{
                                    selected: classes.selected
                                }}
                                key="title-trial"
                                disabled={!trialOutputEnabled}
                            />
                        </Tooltip>
                        <Tooltip title={`alt+4 | ⌥+4`}>
                            <Tab
                                icon={
                                    <FontAwesomeIcon
                                        icon={GetIcon(`book-spells`)}
                                        className={classes.tabIcon}
                                    />
                                }
                                label="Journal"
                                className={classNames(classes.tab, {
                                    [classes.disabled]: !journalOutputEnabled
                                })}
                                classes={{
                                    selected: classes.selected
                                }}
                                key="title-journal"
                                disabled={!journalOutputEnabled}
                            />
                        </Tooltip>
                        <Hotkeys
                            keyName="alt+1,⌥+1,alt+2,⌥+2,alt+3,⌥+3,alt+4,⌥+4"
                            onKeyDown={this.changeTabOnKeyPress.bind(this)}
                        />
                    </Tabs>
                </Sticky>
                <div
                    className={classNames(
                        classes.tabItem,
                        classes.story,
                        `tab-story`
                    )}
                    id={`tab-story`}
                    key={`tab-story`}
                    hidden={index !== 0}
                >
                    <div
                        className={classNames(
                            classes.storyContent,
                            `js-toc-content`
                        )}
                    >
                        {storyOutput}
                        {walkthroughOutputEnabled && (
                            <div
                                className={classes.nextTab}
                                onClick={this.gotoWalkthru}
                                key={1}
                            >
                                <FontAwesomeIcon
                                    icon={GetIcon(`dungeon`)}
                                    className={classes.nextTabIcon}
                                />
                                <div className={classes.nextTabText}>
                                    That's the end of this tale! Follow the{` `}
                                    <span className={classes.nextTabTextSpan}>
                                        Walkthru
                                    </span>
                                    {` `}
                                    to put what you've learnt into practice
                                </div>
                            </div>
                        )}
                        {!walkthroughOutputEnabled && trialOutputEnabled && (
                            <div
                                className={classes.nextTab}
                                onClick={this.gotoTrial}
                                key={2}
                            >
                                <FontAwesomeIcon
                                    icon={GetIcon(`swords`)}
                                    className={classes.nextTabIcon}
                                />
                                <div className={classes.nextTabText}>
                                    That's the end of this tale! Now prepare to
                                    be tested in the{` `}
                                    <span className={classes.nextTabTextSpan}>
                                        Trial
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.storyTocContainer}>
                        <div
                            className={classNames(classes.storyToc, `js-toc`)}
                        />
                    </div>
                </div>
                <div
                    className={classNames(classes.tabItem, `tab-walkthrough`)}
                    key={`tab-walkthrough`}
                    hidden={index !== 1}
                >
                    {quest.quest_message_title && quest.quest_message_content && (
                        <Message type={`tip`} title={quest.quest_message_title}>
                            {RichText.render(
                                quest.quest_message_content,
                                LinkResolver,
                                HtmlSerializer
                            )}
                            {quest.github_link && (
                                <p>
                                    The finished example can be found on{` `}
                                    <Link
                                        href={LinkItem.url(
                                            quest.github_link,
                                            LinkResolver
                                        )}
                                    >
                                        Github
                                    </Link>
                                    .
                                </p>
                            )}
                        </Message>
                    )}
                    {walkthroughOutput}
                    {trialOutputEnabled && (
                        <div
                            className={classes.nextTab}
                            onClick={this.gotoTrial}
                            key={1}
                        >
                            <FontAwesomeIcon
                                icon={GetIcon(`swords`)}
                                className={classes.nextTabIcon}
                            />
                            <div className={classes.nextTabText}>
                                You made it through! Now prepare to be tested in
                                the{` `}
                                <span className={classes.nextTabTextSpan}>
                                    Trial
                                </span>
                            </div>
                        </div>
                    )}
                    {!trialOutputEnabled && journalOutputEnabled && (
                        <div
                            className={classes.nextTab}
                            onClick={this.gotoJournal}
                            key={2}
                        >
                            <FontAwesomeIcon
                                icon={GetIcon(`book-spells`)}
                                className={classes.nextTabIcon}
                            />
                            <div className={classes.nextTabText}>
                                You made it through! Head over to the next quest
                                using the footer nav below or find more
                                resources on this topic in the {` `}
                                <span className={classes.nextTabTextSpan}>
                                    Journal
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={classNames(classes.tabItem, `tab-trial`)}
                    key={`tab-trial`}
                    hidden={index !== 2}
                >
                    {trialOutput}
                    {journalOutputEnabled && (
                        <div
                            className={classes.nextTab}
                            onClick={this.gotoJournal}
                            key={1}
                        >
                            <FontAwesomeIcon
                                icon={GetIcon(`book-spells`)}
                                className={classes.nextTabIcon}
                            />
                            <div className={classes.nextTabText}>
                                Tested yourself enough? Head over to the next
                                quest using the footer nav below or find more
                                resources on this topic in the {` `}
                                <span className={classes.nextTabTextSpan}>
                                    Journal
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={classNames(classes.tabItem, `tab-journal`)}
                    key={`tab-journal`}
                    hidden={index !== 3}
                >
                    {quest.github_link && (
                        <>
                            <h2>Code Example</h2>
                            <QuestLink
                                urlPath={LinkItem.url(
                                    quest.github_link,
                                    LinkResolver
                                )}
                                text={quest.github_repo_name}
                                icon={`github`}
                            />
                        </>
                    )}
                    {journalOutput}
                </div>
                <QuestNavPanel
                    previous={realmQuests[id - 1]}
                    next={realmQuests[id + 1]}
                    realm={{
                        title: `Go back to ${realmName}`,
                        url: `/${realm._meta.uid}`
                    }}
                />
            </Layout>
        );
    }
}

QuestTabs.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles, { withTheme: true })(QuestTabs));

export const pageQuery = graphql`
    query QuestBySlug($uid: String!, $realmUid: String!) {
        prismic {
            quest(uid: $uid, lang: "en-gb") {
                name
                _meta {
                    lastPublicationDate
                }
                description
                icon
                difficulty
                magento_versions {
                    magento_version
                }
                github_link {
                    __typename
                    ... on PRISMIC__ExternalLink {
                        url
                    }
                }
                github_repo_name
                quest_message_title
                quest_message_content
                story {
                    __typename
                    ... on PRISMIC_QuestStoryHeading {
                        type
                        primary {
                            heading
                        }
                    }
                    ... on PRISMIC_QuestStoryCode_block {
                        type
                        primary {
                            language
                            embed_link
                            code
                        }
                    }
                    ... on PRISMIC_QuestStoryEmbed_block {
                        type
                        primary {
                            embed
                        }
                    }
                    ... on PRISMIC_QuestStoryImage_block {
                        type
                        primary {
                            image
                            caption
                            image_title
                        }
                    }
                    ... on PRISMIC_QuestStoryMessage_block {
                        type
                        primary {
                            message_type
                            message_title
                            message_content
                        }
                    }
                    ... on PRISMIC_QuestStoryParagraph_block {
                        type
                        primary {
                            text
                        }
                    }
                    ... on PRISMIC_QuestStoryQuote_block {
                        type
                        primary {
                            quote
                            author
                            link {
                                ... on PRISMIC__ExternalLink {
                                    url
                                }
                            }
                        }
                    }
                    ... on PRISMIC_QuestStoryTable {
                        type
                        primary {
                            heading_1
                            heading_2
                            heading_3
                            heading_4
                            heading_5
                            heading_6
                        }
                        fields {
                            column_1
                            column_2
                            column_3
                            column_4
                            column_5
                            column_6
                        }
                    }
                }
                quest {
                    __typename
                    ... on PRISMIC_QuestQuestHeading {
                        type
                        primary {
                            heading
                        }
                    }
                    ... on PRISMIC_QuestQuestCode_block {
                        type
                        primary {
                            language
                            embed_link
                            code
                        }
                    }
                    ... on PRISMIC_QuestQuestEmbed_block {
                        type
                        primary {
                            embed
                        }
                    }
                    ... on PRISMIC_QuestQuestImage_block {
                        type
                        primary {
                            image
                            caption
                            image_title
                        }
                    }
                    ... on PRISMIC_QuestQuestMessage_block {
                        type
                        primary {
                            message_type
                            message_title
                            message_content
                        }
                    }
                    ... on PRISMIC_QuestQuestParagraph_block {
                        type
                        primary {
                            text
                        }
                    }
                    ... on PRISMIC_QuestQuestQuote_block {
                        type
                        primary {
                            quote
                            author
                            link {
                                ... on PRISMIC__ExternalLink {
                                    url
                                }
                            }
                        }
                    }
                }
                journal {
                    __typename
                    ... on PRISMIC_QuestJournalHeading {
                        type
                        primary {
                            heading
                        }
                    }
                    ... on PRISMIC_QuestJournalLink {
                        type
                        primary {
                            link_type
                            link {
                                __typename
                                ... on PRISMIC__ExternalLink {
                                    url
                                }
                            }
                            title
                        }
                    }
                }
            }
            realm(uid: $realmUid, lang: "en-gb") {
                name
                _meta {
                    uid
                }
            }
        }
    }
`;
