import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hotkeys from 'react-hot-keys';

const styles = (theme) => ({
    navButton: {
        width: `100%`,
        textAlign: `center`,
        height: `50px`,
        padding: `10px 0`,
        '&:hover': {
            textDecoration: `none`
        },
        [theme.breakpoints.up(`md`)]: {
            height: `60px`
        }
    },
    chipWrapper: {
        width: `100%`,
        display: `flex`,
        justifyContent: `space-between`
    },
    navChip: {
        backgroundColor: theme.palette.primary.main,
        color: `#FFF`,
        textTransform: `uppercase`,
        fontWeight: 500,
        cursor: `pointer`,
        maxWidth: `90%`,
        margin: `0 5%`,
        display: `flex`,
        fontSize: `1em`,
        '& > span': {
            width: `85%`,
            minWidth: `85%`,
            maxWidth: `85%`,
            textAlign: `left`,
            padding: 0,
            margin: `0 7.5%`,
            display: `flex`,
            justifyContent: `space-between`,
            [theme.breakpoints.up(`md`)]: {
                width: `95%`,
                minWidth: `95%`,
                maxWidth: `95%`,
                margin: `0 2.5%`
            }
        },
        [theme.breakpoints.up(`md`)]: {
            height: `40px`
        },
        border: `1px solid #000`
    },
    navIcon: {
        width: `8%`,
        minWidth: `1em`,
        maxWidth: `1em`,
        [theme.breakpoints.up(`md`)]: {
            width: `6%`,
            minWidth: `1em`,
            maxWidth: `1em`
        },
        fontSize: 16,
        position: `relative`,
        top: 2,
        marginRight: `2%`,
        order: 1,
        '&.right': {
            marginLeft: `2%`,
            order: 2
        }
    },
    navText: {
        width: `90%`,
        minWidth: `90%`,
        overflow: `hidden`,
        textOverflow: `ellipsis`,
        textAlign: `center`,
        fontWeight: 700,
        [theme.breakpoints.up(`md`)]: {
            width: `93%`,
            minWidth: `93%`
        },
        order: 2,
        '&.left': {
            order: 1
        }
    },
    navLabel: {
        display: `none`,
        [theme.breakpoints.up(`md`)]: {
            display: `inline`
        }
    },
    navNumber: {
        fontFamily: `Londrina Solid`,
        fontWeight: 400,
        fontSize: 14,
        marginRight: 3,
        display: `none`
    }
});

class QuestNavItem extends React.Component {
    gotoQuest = () => {
        window.location = this.props.url;
    };

    render() {
        const {
            classes,
            url,
            title,
            id,
            colour,
            icon,
            iconRight,
            prefixText,
            hotkeys,
            tooltipTitle
        } = this.props;

        return (
            <a href={url} className={classNames(classes.navButton)}>
                <Hotkeys keyName={hotkeys} onKeyDown={this.gotoQuest} />
                <Tooltip
                    title={tooltipTitle}
                    placement={`top`}
                    classes={{
                        popper: classes.tooltipPopper
                    }}
                >
                    <Chip
                        className={classes.navChip}
                        style={{ background: colour }}
                        label={
                            <div className={classes.chipWrapper}>
                                <FontAwesomeIcon
                                    icon={icon}
                                    className={classNames(
                                        classes.navIcon,
                                        `text-shadow-double`,
                                        { [`right`]: iconRight }
                                    )}
                                />
                                <span
                                    className={classNames(
                                        classes.navText,
                                        `text-shadow`,
                                        { [`left`]: iconRight }
                                    )}
                                >
                                    <span className={classes.navLabel}>
                                        {prefixText}
                                    </span>
                                    <span className={classes.navNumber}>
                                        {id}.
                                    </span>
                                    {title}
                                </span>
                            </div>
                        }
                    />
                </Tooltip>
            </a>
        );
    }
}

QuestNavItem.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    colour: PropTypes.string,
    icon: PropTypes.string,
    iconRight: PropTypes.bool,
    prefixText: PropTypes.string,
    hotkeys: PropTypes.string,
    tooltipTitle: PropTypes.string,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(QuestNavItem);
