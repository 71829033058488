import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
    root: {
        textAlign: `center`,
        padding: `40px`,
        borderRadius: `8px`,
        margin: `0 0 30px`,
        [theme.breakpoints.up('sm')]: {
            margin: `0 5% 30px`
        },
        border: `1px solid #000`
    },
    red: {
        backgroundColor: `#CE4244`,
        color: `#FFF`
    },
    yellow: {
        backgroundColor: `#F7DC32`,
        color: `#FFF`
    },
    green: {
        backgroundColor: `#379D60`,
        color: `#FFF`
    },
    scoreTitle: {
        display: `block`
    },
    percentage: {
        fontSize: `30px`,
        fontWeight: 600,
        textTransform: `uppercase`
    },
    percent: {
        fontFamily: `Londrina Solid`,
        fontWeight: 400,
        fontSize: `100px`,
        lineHeight: `100px`
    },
    symbol: {
        fontWeight: 700,
        fontSize: `60px`
    },
    score: {
        fontSize: `20px`,
        [theme.breakpoints.up('sm')]: {
            fontSize: `30px`
        }
    },
    scoreValue: {
        fontFamily: `Londrina Solid`,
        fontWeight: 600
    }
});

class Score extends React.Component {
    getSelected = (answerId, response) => {
        if (typeof response === `object`) {
            if (response[answerId] === true) {
                return true;
            }
        } else {
            if (answerId === response) {
                return true;
            }
        }
        return false;
    };

    isCorrect = (answerId, response, isCorrect) => {
        return this.getSelected(answerId, response) && isCorrect;
    };

    getScore = (questions, responses) => {
        let correctQuestions = 0;

        Object.keys(questions).forEach(function (key) {
            let correctAnswers = 0;

            Object.keys(questions[key].answers).forEach(function (answerId) {
                if (
                    this.isCorrect(
                        answerId,
                        responses[questions[key].id],
                        questions[key].answers[answerId].isCorrect
                    )
                ) {
                    correctAnswers++;
                }
            }, this);

            if (correctAnswers === questions[key].totalCorrectAnswers) {
                correctQuestions++;
            }
        }, this);

        return correctQuestions;
    };

    getPercentage = (score, numberQuestions) => {
        return Math.round((score / numberQuestions) * 100);
    };

    render() {
        const { classes, questions, responses } = this.props;
        let score = this.getScore(questions, responses);
        let numberQuestions = Object.keys(questions).length;
        let percentage = this.getPercentage(score, numberQuestions);

        return (
            <Paper
                className={classNames(
                    classes.root,
                    { [classes.red]: percentage < 40 },
                    { [classes.yellow]: percentage >= 40 && percentage < 70 },
                    { [classes.green]: percentage >= 70 }
                )}
            >
                <div className={classNames(classes.percentage, `text-shadow`)}>
                    <span className={classes.scoreTitle}>You scored</span>
                    <span className={classes.percent}>
                        {percentage}
                        <span className={classes.symbol}>%</span>
                    </span>
                </div>
                <div className={classNames(classes.score, `text-shadow`)}>
                    <span className={classes.scoreValue}>{score}</span> out of
                    {` `}
                    <span className={classes.scoreValue}>
                        {numberQuestions}
                    </span>
                    {` `}
                    questions correct!
                </div>
            </Paper>
        );
    }
}

Score.propTypes = {
    classes: PropTypes.object,
    questions: PropTypes.object,
    responses: PropTypes.object
};

export default withStyles(styles, { withTheme: true })(Score);
