import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import GetIcon from '../../utils/getIcon';
import InsertInlineCodeElements from '../../utils/insertInlineCodeElements';
import Message from '../message';

const styles = (theme) => ({
    root: {
        margin: `40px 0 0`,
        [theme.breakpoints.up('sm')]: {
            margin: `30px 0 0`
        }
    },
    question: {
        marginBottom: `30px`
    },
    questionTitle: {
        fontWeight: 600,
        fontSize: `18px`,
        margin: 0,
        [theme.breakpoints.up('sm')]: {
            fontSize: `24px`
        },
        display: `flex`,
        alignItems: `center`
    },
    questionNumber: {
        fontFamily: `Londrina Solid`,
        color: theme.palette.primary.main,
        fontSize: `36px`,
        textAlign: `center`,
        width: `36px`,
        minWidth: `36px`,
        height: `36px`,
        minHeight: `36px`,
        display: `block`,
        marginTop: `-14px`,
        marginRight: `7px`
    },
    comment: {
        margin: `18px 0 24px 11px`,
        fontSize: `16px`,
        [theme.breakpoints.up('sm')]: {
            fontSize: `18px`
        }
    },
    answers: {
        marginBottom: `10px`
    },
    answer: {
        width: `100%`,
        padding: `0 5px`,
        borderRadius: `4px`,
        margin: 0,
        marginBottom: `15px`,
        '& span': {
            fontFamily: `Raleway`
        },
        '& span:last-child': {
            fontSize: `16px`,
            [theme.breakpoints.up('sm')]: {
                fontSize: `18px`
            },
            color: theme.palette.secondary.main
        }
    },
    correct: {
        padding: `5px`,
        backgroundColor: `#379D60`,
        '& > span:last-child': {
            color: `#FFF`
        },
        border: `1px solid #000`
    },
    incorrect: {
        padding: `5px`,
        backgroundColor: `#CE4244`,
        '& > span:last-child': {
            color: `#FFF`
        },
        border: `1px solid #000`
    },
    explanation: {
        marginBottom: `40px`,
        padding: `16px`,
        backgroundColor: `#ECECEB`,
        borderRadius: `4px`,
        border: `1px solid #000`
    },
    explanationTitle: {
        fontWeight: 600
    },
    explanationText: {
        margin: 0
    }
});

class Results extends React.Component {
    getInput = (answerId, question) => {
        if (question.totalCorrectAnswers > 1) {
            return (
                <Checkbox
                    icon={<FontAwesomeIcon icon={GetIcon(`square`)} />}
                    checkedIcon={
                        <FontAwesomeIcon icon={GetIcon(`check-square`)} />
                    }
                    checked={this.getSelected(
                        answerId,
                        this.props.responses[question.id]
                    )}
                    disabled
                />
            );
        }
        return (
            <Radio
                icon={<FontAwesomeIcon icon={GetIcon(`circle`)} />}
                checkedIcon={<FontAwesomeIcon icon={GetIcon(`dot-circle`)} />}
                checked={this.getSelected(
                    answerId,
                    this.props.responses[question.id]
                )}
                disabled
            />
        );
    };

    getSelected = (answerId, response) => {
        if (typeof response === `object`) {
            if (response[answerId] === true) {
                return true;
            }
        } else {
            if (answerId === response) {
                return true;
            }
        }
        return false;
    };

    isCorrect = (answerId, response, isCorrect) => {
        return this.getSelected(answerId, response) && isCorrect;
    };

    isIncorrect = (answerId, response, isCorrect) => {
        return this.getSelected(answerId, response) && !isCorrect;
    };

    render() {
        const { classes, questions, responses } = this.props;

        return (
            <div className={classes.root}>
                {Object.keys(questions).map((key) => (
                    <div className={classes.question}>
                        <h6 className={classes.questionTitle}>
                            <span
                                className={classNames(
                                    classes.questionNumber,
                                    `text-shadow`
                                )}
                            >
                                {parseInt(key) + 1}
                            </span>
                            {` `}
                            {questions[key].question}
                        </h6>

                        <span className={classes.comment}>
                            {InsertInlineCodeElements(questions[key].comment)}
                        </span>

                        <div className={classNames(classes.answers, `raleway`)}>
                            {Object.keys(questions[key].answers)
                                .filter(
                                    (answerId) =>
                                        questions[key].answers[answerId].label
                                )
                                .map((answerId) => (
                                    <>
                                        <FormControlLabel
                                            control={this.getInput(
                                                answerId,
                                                questions[key]
                                            )}
                                            key={`${questions[key].answers[answerId].id}.${key}`}
                                            label={InsertInlineCodeElements(
                                                questions[key].answers[answerId]
                                                    .label
                                            )}
                                            className={classNames(
                                                classes.answer,
                                                {
                                                    [classes.correct]: this.isCorrect(
                                                        answerId,
                                                        responses[
                                                            questions[key].id
                                                        ],
                                                        questions[key].answers[
                                                            answerId
                                                        ].isCorrect
                                                    )
                                                },
                                                {
                                                    [classes.incorrect]: this.isIncorrect(
                                                        answerId,
                                                        responses[
                                                            questions[key].id
                                                        ],
                                                        questions[key].answers[
                                                            answerId
                                                        ].isCorrect
                                                    )
                                                }
                                            )}
                                        />
                                    </>
                                ))}
                        </div>

                        {questions[key].explanation && (
                            <Message type={`info`} title={`Trial Tips`}>
                                <p>
                                    {InsertInlineCodeElements(
                                        questions[key].explanation
                                    )}
                                </p>
                            </Message>
                        )}

                        {parseInt(key) === Object.keys(questions).length - 1 ? (
                            ``
                        ) : (
                            <Divider />
                        )}
                    </div>
                ))}
            </div>
        );
    }
}

Results.propTypes = {
    classes: PropTypes.object,
    questions: PropTypes.object,
    responses: PropTypes.object
};

export default withStyles(styles, { withTheme: true })(Results);
